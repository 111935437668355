import React from 'react';
import {
  CogIcon,
  ForbiddenIcon,
  HomeIcon,
  MarketingIcon,
  MoneyIcon,
  StatsIcon,
} from '@nimbus-ds/icons';
import { NavTabs } from '@nimbus-ds/patterns';
import { useNavigate, useLocation } from 'react-router-dom';
import { Tooltip } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';

const NavButtons: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleGoToConfigs = (page: string) => navigate(page);

  const { pathname } = useLocation();

  return (
    <NavTabs>
      <NavTabs.Item
        active={pathname === '/'}
        ariaLabel={`${t('Components.NavButtons.Home.Label')}`}
        icon={
          <Tooltip
            content={`${t('components.NavButtons.Home.Helper')}`}
            position="top"
          >
            <HomeIcon size="medium" />
          </Tooltip>
        }
        onClick={() => {
          handleGoToConfigs('/');
        }}
      />
      <NavTabs.Item
        active={pathname === '/statistics'}
        ariaLabel={`${t('StatisticsPage.title')}`}
        icon={
          <Tooltip content={`${t('StatisticsPage.title')}`} position="top">
            <StatsIcon size="medium" />
          </Tooltip>
        }
        onClick={() => {
          handleGoToConfigs('/statistics');
        }}
      />
      <NavTabs.Item
        active={pathname === '/block-products'}
        ariaLabel={`${t('components.NavButtons.BlockProducts.Label')}`}
        icon={
          <Tooltip
            content={`${t('components.NavButtons.BlockProducts.Helper')}`}
            position="top"
          >
            <ForbiddenIcon size="medium" />
          </Tooltip>
        }
        onClick={() => {
          handleGoToConfigs('/block-products');
        }}
      />
      <NavTabs.Item
        active={pathname === '/configs'}
        ariaLabel={`${t('components.NavButtons.Configs.Label')}`}
        icon={
          <Tooltip
            content={`${t('components.NavButtons.Configs.Helper')}`}
            position="top"
          >
            <CogIcon size="medium" />
          </Tooltip>
        }
        onClick={() => {
          handleGoToConfigs('/configs');
        }}
      />
      <NavTabs.Item
        active={pathname === '/news'}
        ariaLabel={`${t('NewsPage.NavButton.Label')}`}
        icon={
          <Tooltip content={`${t('NewsPage.NavButton.Helper')}`} position="top">
            <MarketingIcon size="medium" />
          </Tooltip>
        }
        onClick={() => {
          handleGoToConfigs('/news');
        }}
      />
      <NavTabs.Item
        active={pathname === '/billing'}
        ariaLabel={`${t('components.NavButtons.Billing.Label')}`}
        icon={
          <Tooltip
            content={`${t('components.NavButtons.Billing.Helper')}`}
            position="top"
          >
            <MoneyIcon size="medium" />
          </Tooltip>
        }
        onClick={() => {
          handleGoToConfigs('/billing');
        }}
      />
    </NavTabs>
  );
};

export default NavButtons;
