import React, { useEffect, useState } from 'react';

import { t } from 'i18next';
import { Alert, Box, Button, Toggle, useToast } from '@nimbus-ds/components';
import apiInstance from '../../utils/apiUtils';
import { useNavigate } from 'react-router-dom';
import { CogIcon } from '@nimbus-ds/icons';

const AppStatusBar: React.FC<{ type?: 'alert' | 'toggle' }> = ({
  type = 'alert',
}) => {
  const { addToast } = useToast();

  const [loading, setLoading] = useState(true);
  const [loadingActive, setLoadingActive] = useState(false);
  const [active, setActive] = useState(false);

  useEffect(() => {
    getStatus();
  }, []);

  const getStatus = async () => {
    try {
      const result = await apiInstance.get(`/app/status`);
      const data = { ...(result.data as { is_active: boolean }) };

      setActive(data.is_active);
    } catch (error) {
      setActive(false);
    }
    setLoading(false);
  };

  const startStopApp = async () => {
    try {
      setLoadingActive(true);
      const result = await apiInstance.post(
        `/app/${active ? 'stop' : 'start'}`,
      );

      if (result.status === 200) {
        setActive(!active);
        addToast({
          id: 'config-status-success' + new Date().toISOString(),
          type: 'success',
          text: t(
            `components.alerts.success.${active ? 'disabled' : 'active'}`,
          ),
        });
      } else {
        addToast({
          id: 'config-status-danger' + new Date().toISOString(),
          type: 'danger',
          text: t(`components.alerts.danger.${active ? 'disabled' : 'active'}`),
        });
      }
    } catch (error) {
      console.log(error);
    }
    setLoadingActive(false);
  };

  const navigate = useNavigate();
  const handleGoToConfigs = () => navigate('/configs');

  if (loading || (type === 'alert' && active)) {
    return <></>;
  }

  if (type === 'alert') {
    return (
      <Box marginBottom="5">
        <Alert
          appearance="danger"
          title={`${t('components.AppStatusBar.alert.danger.title')}`}
        >
          {`${t('components.AppStatusBar.alert.danger.message')}`}
          <br />
          {`${t('components.AppStatusBar.alert.danger.message_2')}`}
          <Box display="flex" flexWrap="wrap" gap="2">
            <Button
              disabled={loadingActive}
              onClick={handleGoToConfigs}
              appearance="neutral"
            >
              <CogIcon size="medium" />
              {t('components.NavButtons.Configs.Label')}
            </Button>
          </Box>
        </Alert>
      </Box>
    );
  } else {
    return (
      <Toggle
        disabled={loadingActive}
        name="app-status"
        checked={active}
        onClick={startStopApp}
        label={
          loadingActive
            ? `Aguarde...`
            : `${t('components.ToggleAppStatus.label')}`
        }
      />
    );
  }
};

export default AppStatusBar;
