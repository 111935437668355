import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@nimbus-ds/styles';
import {
  Box,
  Button,
  Card,
  Link,
  Text,
  Thumbnail,
  Title,
} from '@nimbus-ds/components';
import NavButtons from '../NavButtons';
import apiMDInstance from '../../utils/apiMiddlewareUtils';
import { INSApp } from '../../lib/interfaces/news.interface';
import i18n from '../../lib/i18n/i18n';
import { useTranslation } from 'react-i18next';

const BaseLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // The admin currently doesn't support dark mode. When it does, replace `base` value with `currentTheme` to provide dark mode support.
  const { t } = useTranslation();

  const [nsApps, setNSApps] = useState<INSApp[]>([]);
  const [loadingApps, setLoadingApps] = useState<boolean>(true);

  const lang =
    i18n.language === 'en' ? 'en' : i18n.language === 'es' ? 'es' : 'pt';

  useEffect(() => {
    apiMDInstance(`/pub/ns-apps`)
      .then(({ data, status }) => {
        if (status === 200 && data) {
          setNSApps(data);
        }
        setLoadingApps(false);
      })
      .catch(() => {
        setLoadingApps(false);
      });
  }, []);

  return (
    <ThemeProvider theme="base">
      <Box paddingBottom="18">
        {children}

        {(loadingApps || nsApps?.length) && (
          <Box display="grid" gap="4" maxWidth="90%" marginX="auto">
            <Title as="h3">{t('general.apps')}</Title>
            <Text>{t('general.apps_message')}</Text>
            <Box display="flex" flexWrap="wrap" gap="4">
              {loadingApps ? (
                <>
                  <Box width={{ xs: '48%', md: '31.333333%', lg: '23%' }}>
                    <Card padding="base">
                      <Box display="grid" gap="2">
                        <Thumbnail.Skeleton />
                        <Title.Skeleton />
                        <Text.Skeleton />
                        <Button.Skeleton />
                      </Box>
                    </Card>
                  </Box>
                  <Box width={{ xs: '48%', md: '31.333333%', lg: '23%' }}>
                    <Card padding="base">
                      <Box display="grid" gap="2">
                        <Thumbnail.Skeleton />
                        <Title.Skeleton />
                        <Text.Skeleton />
                        <Button.Skeleton />
                      </Box>
                    </Card>
                  </Box>
                </>
              ) : (
                nsApps.map((app) => (
                  <Box key={app._id} width={{ xs: '48%', md: '31.333333%' }}>
                    <Card padding="base">
                      <Card.Header>
                        <Title as="h5">{app.name[lang]}</Title>
                      </Card.Header>
                      <Card.Body>
                        <Box display="flex" alignItems="flex-start" gap="4">
                          <Thumbnail
                            src={app.thumb}
                            width="100px"
                            alt={app.name.pt}
                          />
                          <Box
                            display="grid"
                            gap="2"
                            width="calc(100% - 100px)"
                          >
                            <Box minHeight="60px">
                              <Text fontSize="caption">
                                {app.short_description[lang]}
                              </Text>
                            </Box>
                            <Link
                              appearance="neutral"
                              as="a"
                              href={app.nuvemshop_page}
                              target="_blank"
                            >
                              {lang === 'pt' ? 'Testar' : 'Prueba'}
                            </Link>
                          </Box>
                        </Box>
                      </Card.Body>
                    </Card>
                  </Box>
                ))
              )}
            </Box>
          </Box>
        )}

        <NavButtons />
      </Box>
    </ThemeProvider>
  );
};

export default BaseLayout;
