import React, { useEffect, useState } from 'react';
import nexo from '../../nexoClient';
import {
  Box,
  Button,
  Alert,
  Spinner,
  Input,
  Popover,
  Icon,
  Card,
  Title,
  Text,
  Table,
} from '@nimbus-ds/components';
import { Calendar, EmptyMessage, Layout, Page } from '@nimbus-ds/patterns';
import { navigateHeader } from '@tiendanube/nexo';
import { useTranslation } from 'react-i18next';
import apiInstance from '../../utils/apiUtils';
import {
  CalendarIcon,
  ShoppingCartIcon,
  MoneyIcon,
  BoxPackedIcon,
  TagIcon,
  ListIcon,
} from '@nimbus-ds/icons';
import { format } from 'date-fns';
import { formatDate, formatMoney } from '../../utils/formatUtils';
import { IGroup } from '../../lib/interfaces/groups.interfaces';

/**
 * Componente de seleção de datas
 */
const DatePicker: React.FC<{
  label: string;
  selectedDate: Date | undefined;
  onChange: (date: Date) => void;
}> = ({ label, selectedDate, onChange }) => {
  return (
    <Popover
      content={
        <Calendar
          mode="single"
          showOutsideDays
          selected={selectedDate}
          onSelect={(date) => onChange(date || new Date())}
          hideBorder
          fullWidthDays
        />
      }
      padding="none"
      arrow={false}
    >
      <Input
        readOnly
        value={selectedDate ? format(selectedDate, 'dd-MM-yyyy') : ''}
        placeholder="Selecione uma data"
        append={<Icon color="neutral-textLow" source={<CalendarIcon />} />}
        appendPosition="end"
      />
    </Popover>
  );
};

/**
 * Componente para exibir estatísticas
 */
const StatisticCard: React.FC<{
  icon: React.ReactNode;
  title: string;
  value: string | number;
  loading: boolean;
}> = ({ icon, title, value, loading }) => {
  return (
    <Card>
      <Card.Body>
        <Box display="flex" gap="5" alignItems="center">
          <Icon source={icon} />
          <Box>
            <Title as="h4">{title}</Title>
            {loading ? (
              <Text.Skeleton width="40%" />
            ) : (
              <Text fontWeight="bold">{value}</Text>
            )}
          </Box>
        </Box>
      </Card.Body>
    </Card>
  );
};

/**
 * Página de Estatísticas
 */
const StatisticsPage: React.FC = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<any>(null);
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();

  useEffect(() => {
    navigateHeader(nexo, { goTo: '/', text: `${t('general.back')}` });

    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    setStartDate(firstDayOfMonth);
    setEndDate(today);

    getOrders(firstDayOfMonth, today);
  }, []);

  const getOrders = async (start: Date, end: Date) => {
    setLoading(true);
    setError(null);

    try {
      const startFormatted = start.toISOString().split('T')[0];
      const endFormatted = end.toISOString().split('T')[0];

      const result = await apiInstance.get(
        `/statistics/orders?startDate=${startFormatted}&endDate=${endFormatted}`,
      );

      setData(result.data);
    } catch (error) {
      console.error(error);
      setError(t('StatisticsPage.EmptyOrders'));
    }
    setLoading(false);
  };

  /**
   * Função para obter o nome do grupo a partir do ID
   */
  const getGroupName = (kitId: string) => {
    const kit = data?.kitsDetails.find((kit: any) => kit._id === kitId);
    return kit ? kit.name : 'Desconhecido';
  };

  return (
    <Page>
      <Page.Header
        title={t('StatisticsPage.title')}
        subtitle={`${t('StatisticsPage.subtitle')}`}
      />
      <Page.Body marginX="auto">
        <Layout columns="1" gap="6">
          {/* Filtros */}
          <Layout.Section>
            <Box
              display="flex"
              gap="4"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex" gap="4" alignItems="center">
                <DatePicker
                  label={t('StatisticsPage.Filter.DateInit')}
                  selectedDate={startDate}
                  onChange={setStartDate}
                />
                <DatePicker
                  label={t('StatisticsPage.Filter.DateEnd')}
                  selectedDate={endDate}
                  onChange={setEndDate}
                />
                <Button
                  appearance="primary"
                  onClick={() =>
                    startDate && endDate && getOrders(startDate, endDate)
                  }
                >
                  {t('StatisticsPage.Filter.Cta')}
                </Button>
              </Box>
              {data && data.consultDate && (
                <Text>
                  {t('StatisticsPage.Filter.HelperText').replace(
                    /{x}/g,
                    formatDate(data.consultDate),
                  )}
                </Text>
              )}
            </Box>
            {error && (
              <Layout.Section marginTop="6">
                <Alert appearance="danger">{error}</Alert>
              </Layout.Section>
            )}
            {loading && (
              <Layout.Section marginTop="6">
                <Alert appearance="warning">
                  {t('StatisticsPage.loadingText')}
                </Alert>
              </Layout.Section>
            )}
          </Layout.Section>
        </Layout>
        <Layout columns="2 - symmetric" gap="6" marginTop="6">
          {/* Status de carregamento e erro */}
          {/* Estatísticas Gerais */}
          {loading || data ? (
            <>
              <Layout.Section>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(auto-fit, minmax(250px, 1fr))"
                  gap="4"
                >
                  <StatisticCard
                    icon={<ShoppingCartIcon size="large" />}
                    title={t('StatisticsPage.Cards.totalOrders.Title')}
                    value={data?.totalOrders}
                    loading={loading}
                  />
                  <StatisticCard
                    icon={<MoneyIcon size="large" />}
                    title={t('StatisticsPage.Cards.totalRevenue.Title')}
                    value={formatMoney(data?.totalRevenue)}
                    loading={loading}
                  />
                  <StatisticCard
                    icon={<BoxPackedIcon size="large" />}
                    title={t('StatisticsPage.Cards.totalKitOrders.Title')}
                    value={data?.totalKitOrders}
                    loading={loading}
                  />
                  <StatisticCard
                    icon={<TagIcon size="large" />}
                    title={t('StatisticsPage.Cards.totalKitsSold.Title')}
                    value={data?.totalKitsSold}
                    loading={loading}
                  />
                  {/* <StatisticCard
                    icon={<ListIcon />}
                    title={t('StatisticsPage.Cards.totalProductsInKits.Title')}
                    value={data.totalProductsInKits}
                  /> */}
                </Box>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(auto-fit, minmax(250px, 1fr))"
                  gap="4"
                >
                  <StatisticCard
                    icon={<BoxPackedIcon size="large" />}
                    title={t('StatisticsPage.Cards.highestSellingKit.Title')}
                    value={getGroupName(data?.highestSellingKit)}
                    loading={loading}
                  />
                  <StatisticCard
                    icon={<BoxPackedIcon size="large" />}
                    title={t('StatisticsPage.Cards.lowestSellingKit.Title')}
                    value={getGroupName(data?.lowestSellingKit)}
                    loading={loading}
                  />
                  {/* <StatisticCard
                    icon={<MoneyIcon />}
                    title={t('StatisticsPage.Cards.highestRevenueKit.Title')}
                    value={formatMoney(data.highestRevenueKit)}
                  />
                  <StatisticCard
                    icon={<MoneyIcon />}
                    title={t('StatisticsPage.Cards.lowestRevenueKit.Title')}
                    value={formatMoney(data.lowestRevenueKit)}
                  /> */}
                </Box>
              </Layout.Section>

              {/* Tabela de Grupos Vendidos */}
              <Layout.Section>
                <Box display="grid" gap="5">
                  {/* <Title as="h4">{t('StatisticsPage.Table.Title')}</Title> */}
                  {loading || data?.kitsDetails.length ? (
                    <Table>
                      <Table.Head>
                        <Table.Row>
                          <Table.Cell>
                            {t('StatisticsPage.Table.Cell1')}
                          </Table.Cell>
                          <Table.Cell width="150px">
                            <Box as="span" textAlign="center" display="block">
                              {t('StatisticsPage.Table.Cell2')}
                            </Box>
                          </Table.Cell>
                        </Table.Row>
                      </Table.Head>
                      <Table.Body>
                        {loading ? (
                          <>
                            <Table.Row>
                              <Table.Cell>
                                <Text.Skeleton width="100%" />
                              </Table.Cell>
                              <Table.Cell>
                                <Text.Skeleton width="100%" />
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>
                                <Text.Skeleton width="100%" />
                              </Table.Cell>
                              <Table.Cell>
                                <Text.Skeleton width="100%" />
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>
                                <Text.Skeleton width="100%" />
                              </Table.Cell>
                              <Table.Cell>
                                <Text.Skeleton width="100%" />
                              </Table.Cell>
                            </Table.Row>
                          </>
                        ) : (
                          data?.kitsDetails &&
                          data?.kitsDetails.map(
                            (kit: { _id: string; name: string }) => (
                              <Table.Row key={kit._id}>
                                <Table.Cell>{kit.name}</Table.Cell>
                                <Table.Cell>
                                  <Box
                                    as="span"
                                    textAlign="center"
                                    display="block"
                                  >
                                    {data.kitsData[kit._id]?.count || 0}
                                  </Box>
                                </Table.Cell>
                              </Table.Row>
                            ),
                          )
                        )}
                      </Table.Body>
                    </Table>
                  ) : (
                    <Card>
                      <Card.Body>
                        <EmptyMessage
                          title=""
                          text={`${t('StatisticsPage.Table.Empty')}`}
                        />
                      </Card.Body>
                    </Card>
                  )}
                </Box>
              </Layout.Section>
            </>
          ) : (
            <></>
          )}
        </Layout>
      </Page.Body>
    </Page>
  );
};

export default StatisticsPage;
