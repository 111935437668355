import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import apiMDInstance from './utils/apiMiddlewareUtils';
import { IBillingStoreInfo } from './lib/interfaces/billing.interface';

interface ProtectedRouteProps {
  element: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
  const [permission, setPermission] = useState<boolean | null>(null); // Inicialmente null
  const navigate = useNavigate();

  useEffect(() => {
    if (process.env.REACT_APP_NODE_ENV === 'dev') {
      setPermission(true);
    } else {
      apiMDInstance('/billing/check-payment')
        .then(({ data, status }) => {
          if (status === 200 && data) {
            const billingData = data as IBillingStoreInfo;
            if (
              billingData.billing_from === 'nuvemshop' ||
              (billingData.app_status === true &&
                billingData.billing_from === 'stripe' &&
                billingData.stripe_customer_id !== '' &&
                billingData.stripe_subscription_id !== '')
            ) {
              setPermission(true);
              return;
            }
          }
          setPermission(false);
        })
        .catch(() => {
          setPermission(true);
        });
    }
  }, []);

  useEffect(() => {
    if (permission === false) {
      navigate('/billing', { replace: true }); // Redireciona se não houver permissão
    }
  }, [permission, navigate]);

  if (permission === null) {
    // Exibe carregamento enquanto `permission` não é avaliada
    return <div>Loading permissions...</div>;
  }

  // Retorna o elemento protegido se houver permissão
  return permission ? element : null;
};

export default ProtectedRoute;
