import React, { ChangeEvent, useEffect, useState } from 'react';

import nexo from '../../nexoClient';

import { Page, Layout, FormField, HelpLink } from '@nimbus-ds/patterns';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Icon,
  Input,
  Link,
  Select,
  Spinner,
  Tabs,
  Text,
  Title,
  useToast,
} from '@nimbus-ds/components';
import { navigateHeader } from '@tiendanube/nexo';
import { useTranslation } from 'react-i18next';
import {
  MethodOptions,
  IStoreConfig,
  BuyTogetherDisplayModeOptions,
  VariationsModeOptions,
  defaultStoreConfig,
  KitDisplayModeOptions,
} from '../../lib/interfaces/config.interfaces';
import apiInstance from '../../utils/apiUtils';
import { AppStatusBar, InputColor } from '../../components';
import BuyTogetherModels from './BuyTogetherModels';
import { ExternalLinkIcon } from '@nimbus-ds/icons';

const ConfigsPage: React.FC = () => {
  const { t } = useTranslation();

  const { addToast } = useToast();
  const [loadingGetConfig, setLoadingGetConfig] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [config, setConfig] = useState<IStoreConfig | null>(null);

  useEffect(() => {
    navigateHeader(nexo, { goTo: '/', text: `${t('general.back')}` });
    getConfig();
    setLoading(false);
  }, []);

  const handleChangeConfig = (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { name, value, type } = event.target;

    const helpConfig: any = { ...config };
    helpConfig[name] =
      type === 'checkbox' ? (event.target as HTMLInputElement).checked : value;
    setConfig({ ...helpConfig });
    return false;
  };

  const getConfig = async () => {
    try {
      const result = await apiInstance.get(`/store/config`);
      const tempConfig = { ...(result.data as IStoreConfig) };

      setConfig({ ...defaultStoreConfig, ...tempConfig });
    } catch (error) {
      setConfig({ ...defaultStoreConfig });
    }
    setLoadingGetConfig(false);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const data = { ...config };
      delete data._id;
      delete data.createdAt;
      delete data.updatedAt;
      delete data.store_id;
      const result = await apiInstance.post(`/store/config`, data);
      addToast({
        id: 'submit' + new Date().toISOString(),
        type: 'success',
        text: `${t('ConfigsPage.submit_success')}`,
        position: 4,
      });
      setLoading(false);
    } catch (error) {
      addToast({
        id: 'submit' + new Date().toISOString(),
        type: 'danger',
        text: `${t('ConfigsPage.submit_danger')}`,
        position: 4,
      });
      setLoading(false);
    }
  };

  const handleRecalcKits = async () => {
    try {
      setLoading(true);
      const result = await apiInstance.post(`/groups/prices/sync`, {});
      if (result.data.groups > 0) {
        addToast({
          id: 'submit' + new Date().toISOString(),
          type: 'success',
          text: `${result.data.groups} ${t(
            'ConfigsPage.recalc_kits.submit_success',
          )}`,
          position: 4,
        });
      } else {
        addToast({
          id: 'submit' + new Date().toISOString(),
          type: 'success',
          text: `${t('ConfigsPage.recalc_kits.submit_success_no_groups')}`,
          position: 4,
        });
      }
      setLoading(false);
    } catch (error) {
      addToast({
        id: 'submit' + new Date().toISOString(),
        type: 'danger',
        text: `${t('ConfigsPage.recalc_kits.submit_danger')}`,
        position: 4,
      });
      setLoading(false);
    }
  };

  const PositionMethodOptions: React.FC = () => {
    return (
      <>
        <Select.Option
          value={MethodOptions.BEFORE}
          label={t('ConfigsPage.form.fields.general.position_method_option_1')}
        />
        <Select.Option
          value={MethodOptions.PREPEND}
          label={t('ConfigsPage.form.fields.general.position_method_option_2')}
        />
        <Select.Option
          value={MethodOptions.IN}
          label={t('ConfigsPage.form.fields.general.position_method_option_3')}
        />
        <Select.Option
          value={MethodOptions.APPEND}
          label={t('ConfigsPage.form.fields.general.position_method_option_4')}
        />
        <Select.Option
          value={MethodOptions.AFTER}
          label={t('ConfigsPage.form.fields.general.position_method_option_5')}
        />
      </>
    );
  };

  return (
    <>
      <Page>
        <Page.Header
          title={t('ConfigsPage.title')}
          buttonStack={
            <Box display="flex" gap="2">
              <AppStatusBar type="toggle" />
            </Box>
          }
        />
        <Page.Body>
          <Layout columns="1">
            <Layout.Section>
              <Box
                display="flex"
                gap="6"
                flexWrap={{ xs: 'wrap', md: 'nowrap' }}
                boxSizing="border-box"
              >
                <Box
                  maxWidth="100%"
                  width={{ xs: 'auto', md: '40%' }}
                  order={{ xs: '2', md: '1' }}
                  boxSizing="border-box"
                >
                  <Tabs preSelectedTab={0} fullWidth={true}>
                    {/* TAB COMPRE JUNTO */}
                    <Tabs.Item label={t('ConfigsPage.tabs.buy-together.title')}>
                      <Box display="grid" gap="6">
                        {/* configs buy-together rules  */}
                        <Card>
                          <Card.Header
                            title={`${t(
                              'ConfigsPage.tabs.buy-together.rules.title',
                            )}`}
                          />
                          <Card.Body>
                            <Box display="grid" gap="6">
                              <Box width="100%" display="grid" gap="2">
                                <FormField
                                  label={`${t(
                                    'ConfigsPage.form.fields.buy_together_display_mode.label',
                                  )}`}
                                >
                                  <Select
                                    disabled={loadingGetConfig || loading}
                                    id="buy_together_display_mode"
                                    name="buy_together_display_mode"
                                    onChange={handleChangeConfig}
                                    value={config?.buy_together_display_mode}
                                  >
                                    <Select.Option
                                      value={BuyTogetherDisplayModeOptions.GRID}
                                      label={t(
                                        'ConfigsPage.form.fields.buy_together_display_mode.option_1',
                                      )}
                                    />
                                    <Select.Option
                                      value={BuyTogetherDisplayModeOptions.LIST}
                                      label={t(
                                        'ConfigsPage.form.fields.buy_together_display_mode.option_2',
                                      )}
                                    />
                                    <Select.Option
                                      value={
                                        BuyTogetherDisplayModeOptions.MINIMAL
                                      }
                                      label={t(
                                        'ConfigsPage.form.fields.buy_together_display_mode.option_3',
                                      )}
                                    />
                                  </Select>
                                </FormField>
                              </Box>
                              <Box width="100%" display="grid" gap="2">
                                <FormField
                                  label={`${t(
                                    'ConfigsPage.form.fields.variations_mode.label',
                                  )}`}
                                >
                                  <Select
                                    disabled={loadingGetConfig || loading}
                                    id="variations_mode"
                                    name="variations_mode"
                                    onChange={handleChangeConfig}
                                    value={config?.variations_mode}
                                  >
                                    <Select.Option
                                      value={VariationsModeOptions.BUTTONS}
                                      label={t(
                                        'ConfigsPage.form.fields.variations_mode.option_1',
                                      )}
                                    />
                                    <Select.Option
                                      value={VariationsModeOptions.SELECT}
                                      label={t(
                                        'ConfigsPage.form.fields.variations_mode.option_2',
                                      )}
                                    />
                                  </Select>
                                </FormField>
                              </Box>
                            </Box>
                          </Card.Body>
                        </Card>
                        {/* configs buy-together texts  */}
                        <Card>
                          <Card.Header
                            title={`${t(
                              'ConfigsPage.tabs.buy-together.texts.title',
                            )}`}
                          />
                          <Card.Body>
                            <Box display="grid" gap="6">
                              <FormField
                                label={`${t(
                                  'ConfigsPage.form.fields.buy_together_title.label',
                                )}`}
                              >
                                <Input
                                  disabled={loadingGetConfig || loading}
                                  id="buy_together_title"
                                  name="buy_together_title"
                                  onChange={handleChangeConfig}
                                  value={config?.buy_together_title}
                                  placeholder={`${t(
                                    'ConfigsPage.form.fields.buy_together_title.placeholder',
                                  )}`}
                                />
                              </FormField>
                              <FormField
                                label={`${t(
                                  'ConfigsPage.form.fields.buy_together_message_discount.label',
                                )}`}
                                helpText={`${t(
                                  'ConfigsPage.form.fields.buy_together_message_discount.help_text',
                                )}`}
                                showHelpText={true}
                              >
                                <Input
                                  disabled={loadingGetConfig || loading}
                                  id="buy_together_message_discount"
                                  name="buy_together_message_discount"
                                  onChange={handleChangeConfig}
                                  value={config?.buy_together_message_discount}
                                  placeholder={`${t(
                                    'ConfigsPage.form.fields.buy_together_message_discount.placeholder',
                                  )}`}
                                />
                              </FormField>
                              <FormField
                                label={`${t(
                                  'ConfigsPage.form.fields.buy_together_message_no_discount.label',
                                )}`}
                                helpText={`${t(
                                  'ConfigsPage.form.fields.buy_together_message_no_discount.help_text',
                                )}`}
                                showHelpText={true}
                              >
                                <Input
                                  disabled={loadingGetConfig || loading}
                                  id="buy_together_message_no_discount"
                                  name="buy_together_message_no_discount"
                                  onChange={handleChangeConfig}
                                  value={
                                    config?.buy_together_message_no_discount
                                  }
                                  placeholder={`${t(
                                    'ConfigsPage.form.fields.buy_together_message_no_discount.placeholder',
                                  )}`}
                                />
                              </FormField>
                              <FormField
                                label={`${t(
                                  'ConfigsPage.form.fields.buy_together_cta_text.label',
                                )}`}
                              >
                                <Input
                                  disabled={loadingGetConfig || loading}
                                  id="buy_together_cta_text"
                                  name="buy_together_cta_text"
                                  onChange={handleChangeConfig}
                                  value={config?.buy_together_cta_text}
                                  placeholder={`${t(
                                    'ConfigsPage.form.fields.buy_together_cta_text.placeholder',
                                  )}`}
                                />
                              </FormField>
                              <FormField
                                label={`${t(
                                  'ConfigsPage.form.fields.buy_together_enabled_option_text.label',
                                )}`}
                                helpText={`${t(
                                  'ConfigsPage.form.fields.buy_together_enabled_option_text.help_text',
                                )}`}
                                showHelpText={true}
                              >
                                <Input
                                  disabled={loadingGetConfig || loading}
                                  id="buy_together_enabled_option_text"
                                  name="buy_together_enabled_option_text"
                                  onChange={handleChangeConfig}
                                  value={
                                    config?.buy_together_enabled_option_text
                                  }
                                  placeholder={`${t(
                                    'ConfigsPage.form.fields.buy_together_enabled_option_text.placeholder',
                                  )}`}
                                />
                              </FormField>
                              <FormField
                                label={`${t(
                                  'ConfigsPage.form.fields.buy_together_disabled_option_text.label',
                                )}`}
                                helpText={`${t(
                                  'ConfigsPage.form.fields.buy_together_disabled_option_text.help_text',
                                )}`}
                                showHelpText={true}
                              >
                                <Input
                                  disabled={loadingGetConfig || loading}
                                  id="buy_together_disabled_option_text"
                                  name="buy_together_disabled_option_text"
                                  onChange={handleChangeConfig}
                                  value={
                                    config?.buy_together_disabled_option_text
                                  }
                                  placeholder={`${t(
                                    'ConfigsPage.form.fields.buy_together_disabled_option_text.placeholder',
                                  )}`}
                                />
                              </FormField>
                            </Box>
                          </Card.Body>
                        </Card>
                        {/* buy_together_desktop_position_method  */}
                        <Card>
                          <Card.Header
                            title={`${t(
                              'ConfigsPage.tabs.buy-together.desktop_mode.title',
                            )}`}
                          />
                          <Card.Body>
                            <Box display="grid" gap="6">
                              <Box width="100%" display="grid" gap="2">
                                <FormField
                                  label={`${t(
                                    'ConfigsPage.form.fields.buy_together_desktop_position_method.label',
                                  )}`}
                                >
                                  <Box
                                    display="flex"
                                    flexWrap={{ xs: 'wrap', md: 'nowrap' }}
                                    gap="4"
                                    alignItems="center"
                                  >
                                    <Box width={{ xs: '100%', md: '100px' }}>
                                      <Select
                                        disabled={loadingGetConfig || loading}
                                        id="buy_together_desktop_position_method"
                                        name="buy_together_desktop_position_method"
                                        onChange={handleChangeConfig}
                                        value={
                                          config?.buy_together_desktop_position_method
                                        }
                                      >
                                        <PositionMethodOptions />
                                      </Select>
                                    </Box>
                                    <Text as="span">
                                      {t(
                                        'ConfigsPage.form.fields.buy_together_desktop_position_method.divisor',
                                      )}
                                    </Text>
                                    <Box width={{ xs: '100%', md: '130px' }}>
                                      <Input
                                        disabled={loadingGetConfig || loading}
                                        id="buy_together_desktop_position"
                                        name="buy_together_desktop_position"
                                        onChange={handleChangeConfig}
                                        value={
                                          config?.buy_together_desktop_position
                                        }
                                        placeholder="#element-id"
                                      />
                                    </Box>
                                  </Box>
                                </FormField>
                              </Box>
                            </Box>
                          </Card.Body>
                        </Card>
                        {/* buy_together_mobile_position_method  */}
                        <Card>
                          <Card.Header
                            title={`${t(
                              'ConfigsPage.tabs.buy-together.mobile_mode.title',
                            )}`}
                          />
                          <Card.Body>
                            <Box display="grid" gap="6">
                              <Box width="100%" display="grid" gap="2">
                                <FormField
                                  label={`${t(
                                    'ConfigsPage.form.fields.buy_together_mobile_position_method.label',
                                  )}`}
                                >
                                  <Box
                                    display="flex"
                                    flexWrap={{ xs: 'wrap', md: 'nowrap' }}
                                    gap="4"
                                    alignItems="center"
                                  >
                                    <Box width={{ xs: '100%', md: '100px' }}>
                                      <Select
                                        disabled={loadingGetConfig || loading}
                                        id="buy_together_mobile_position_method"
                                        name="buy_together_mobile_position_method"
                                        onChange={handleChangeConfig}
                                        value={
                                          config?.buy_together_mobile_position_method
                                        }
                                      >
                                        <PositionMethodOptions />
                                      </Select>
                                    </Box>
                                    <Text as="span">
                                      {t(
                                        'ConfigsPage.form.fields.buy_together_mobile_position_method.divisor',
                                      )}
                                    </Text>
                                    <Box width={{ xs: '100%', md: '130px' }}>
                                      <Input
                                        disabled={loadingGetConfig || loading}
                                        id="buy_together_mobile_position"
                                        name="buy_together_mobile_position"
                                        onChange={handleChangeConfig}
                                        value={
                                          config?.buy_together_mobile_position
                                        }
                                        placeholder="#element-id"
                                      />
                                    </Box>
                                  </Box>
                                </FormField>
                              </Box>
                            </Box>
                          </Card.Body>
                        </Card>
                      </Box>
                    </Tabs.Item>
                    {/* TAB KITS */}
                    <Tabs.Item label={t('ConfigsPage.tabs.kit.title')}>
                      <Box display="grid" gap="6">
                        <Card>
                          <Card.Header
                            title={`${t(
                              'ConfigsPage.tabs.kit.cards.recalc.title',
                            )}`}
                          />
                          <Card.Body>
                            <Box display="grid" gap="6">
                              <Text>{`${t(
                                'ConfigsPage.tabs.kit.cards.recalc.message',
                              )}`}</Text>
                              <Button
                                appearance="neutral"
                                onClick={handleRecalcKits}
                              >
                                {`${t(
                                  'ConfigsPage.tabs.kit.cards.recalc.cta',
                                )}`}
                              </Button>
                            </Box>
                          </Card.Body>
                        </Card>
                        <Card>
                          <Card.Header
                            title={`${t(
                              'ConfigsPage.tabs.kit.cards.models.title',
                            )}`}
                          />
                          <Card.Body>
                            <Box display="grid" gap="6">
                              <FormField
                                label={`${t(
                                  'ConfigsPage.form.fields.kit_display_mode.label',
                                )}`}
                              >
                                <Select
                                  disabled={loadingGetConfig || loading}
                                  id="kit_display_mode"
                                  name="kit_display_mode"
                                  onChange={handleChangeConfig}
                                  value={config?.kit_display_mode}
                                >
                                  <Select.Option
                                    value={KitDisplayModeOptions.DEFAULT}
                                    label={t(
                                      'ConfigsPage.form.fields.kit_display_mode.option_1',
                                    )}
                                  />
                                  <Select.Option
                                    value={KitDisplayModeOptions.BLOCKS}
                                    label={t(
                                      'ConfigsPage.form.fields.kit_display_mode.option_2',
                                    )}
                                  />
                                  {/* <Select.Option
                                    value={BuyTogetherDisplayModeOptions.LIST}
                                    label={t(
                                      'ConfigsPage.form.fields.kit_display_mode.option_2',
                                    )}
                                  /> */}
                                </Select>
                              </FormField>

                              <Checkbox
                                disabled={loadingGetConfig || loading}
                                name="group_kit_in_cart"
                                label={`${t(
                                  'ConfigsPage.form.fields.group_kit_in_cart.label',
                                )}`}
                                checked={config?.group_kit_in_cart || false}
                                onChange={handleChangeConfig}
                              />

                              <Checkbox
                                disabled={loadingGetConfig || loading}
                                name="show_label_price_from_in_kit"
                                label={`${t(
                                  'ConfigsPage.form.fields.show_label_price_from_in_kit.label',
                                )}`}
                                checked={
                                  config?.show_label_price_from_in_kit || false
                                }
                                onChange={handleChangeConfig}
                              />

                              <Checkbox
                                disabled={loadingGetConfig || loading}
                                name="show_image_in_kit"
                                label={`${t(
                                  'ConfigsPage.form.fields.show_image_in_kit.label',
                                )}`}
                                checked={config?.show_image_in_kit || false}
                                onChange={handleChangeConfig}
                              />

                              <Checkbox
                                disabled={loadingGetConfig || loading}
                                name="kit_block_buy_not_stock"
                                label={`${t(
                                  'ConfigsPage.form.fields.kit_block_buy_not_stock.label',
                                )}`}
                                checked={
                                  config?.kit_block_buy_not_stock || false
                                }
                                onChange={handleChangeConfig}
                              />
                            </Box>
                          </Card.Body>
                        </Card>
                        <Card>
                          <Card.Header
                            title={`${t(
                              'ConfigsPage.tabs.kit.cards.anchors.title',
                            )}`}
                          />
                          <Card.Body>
                            <Box display="grid" gap="6">
                              <Text>
                                {t(
                                  'ConfigsPage.tabs.kit.cards.anchors.description',
                                )}
                              </Text>
                              <FormField
                                label={`${t(
                                  'ConfigsPage.form.fields.kit_anchor_element_price_de.label',
                                )}`}
                              >
                                <Input
                                  disabled={loadingGetConfig || loading}
                                  id="kit_anchor_element_price_de"
                                  name="kit_anchor_element_price_de"
                                  onChange={handleChangeConfig}
                                  value={config?.kit_anchor_element_price_de}
                                  placeholder={`${'[data-store*="product-price-"] > .js-compare-price-display'}`}
                                />
                              </FormField>
                              <FormField
                                label={`${t(
                                  'ConfigsPage.form.fields.kit_anchor_element_price_por.label',
                                )}`}
                              >
                                <Input
                                  disabled={loadingGetConfig || loading}
                                  id="kit_anchor_element_price_por"
                                  name="kit_anchor_element_price_por"
                                  onChange={handleChangeConfig}
                                  value={config?.kit_anchor_element_price_por}
                                  placeholder={`${'[data-store*="product-price-"] > .js-price-display '}`}
                                />
                              </FormField>
                              <FormField
                                label={`${t(
                                  'ConfigsPage.form.fields.kit_anchor_element_variants.label',
                                )}`}
                              >
                                <Input
                                  disabled={loadingGetConfig || loading}
                                  id="kit_anchor_element_variants"
                                  name="kit_anchor_element_variants"
                                  onChange={handleChangeConfig}
                                  value={config?.kit_anchor_element_variants}
                                  placeholder={`${'.js-product-variants'}`}
                                />
                              </FormField>
                              <FormField
                                label={`${t(
                                  'ConfigsPage.form.fields.kit_anchor_element_action.label',
                                )}`}
                              >
                                <Input
                                  disabled={loadingGetConfig || loading}
                                  id="kit_anchor_element_action"
                                  name="kit_anchor_element_action"
                                  onChange={handleChangeConfig}
                                  value={config?.kit_anchor_element_action}
                                  placeholder={`${'.js-addtocart'}`}
                                />
                              </FormField>
                              <FormField
                                label={`${t(
                                  'ConfigsPage.form.fields.kit_anchor_showcase_item.label',
                                )}`}
                              >
                                <Input
                                  disabled={loadingGetConfig || loading}
                                  id="kit_anchor_showcase_item"
                                  name="kit_anchor_showcase_item"
                                  onChange={handleChangeConfig}
                                  value={config?.kit_anchor_showcase_item}
                                  placeholder={`${'[data-store*="product-item-"]'}`}
                                />
                              </FormField>
                              <FormField
                                label={`${t(
                                  'ConfigsPage.form.fields.kit_anchor_showcase_item_cta.label',
                                )}`}
                              >
                                <Input
                                  disabled={loadingGetConfig || loading}
                                  id="kit_anchor_showcase_item_cta"
                                  name="kit_anchor_showcase_item_cta"
                                  onChange={handleChangeConfig}
                                  value={config?.kit_anchor_showcase_item_cta}
                                  placeholder={`${'.js-addtocart'}`}
                                />
                              </FormField>
                              <Title as="h4">
                                {t(
                                  'ConfigsPage.tabs.kit.cards.anchors.title_cart',
                                )}
                              </Title>

                              <FormField
                                label={`${t(
                                  'ConfigsPage.form.fields.kit_anchor_cart_cta.label',
                                )}`}
                              >
                                <Input
                                  disabled={loadingGetConfig || loading}
                                  id="kit_anchor_cart_cta"
                                  name="kit_anchor_cart_cta"
                                  onChange={handleChangeConfig}
                                  value={config?.kit_anchor_cart_cta}
                                  placeholder={`${'[data-toggle="#modal-cart"]'}`}
                                />
                              </FormField>
                              <FormField
                                label={`${t(
                                  'ConfigsPage.form.fields.kit_anchor_cart_items.label',
                                )}`}
                              >
                                <Input
                                  disabled={loadingGetConfig || loading}
                                  id="kit_anchor_cart_items"
                                  name="kit_anchor_cart_items"
                                  onChange={handleChangeConfig}
                                  value={config?.kit_anchor_cart_items}
                                  placeholder={`${'[data-store="cart-form"]'}`}
                                />
                              </FormField>
                              <FormField
                                label={`${t(
                                  'ConfigsPage.form.fields.kit_anchor_cart_item.label',
                                )}`}
                              >
                                <Input
                                  disabled={loadingGetConfig || loading}
                                  id="kit_anchor_cart_item"
                                  name="kit_anchor_cart_item"
                                  onChange={handleChangeConfig}
                                  value={config?.kit_anchor_cart_item}
                                  placeholder={`${'[data-store*="cart-item-"]'}`}
                                />
                              </FormField>
                              <FormField
                                label={`${t(
                                  'ConfigsPage.form.fields.kit_anchor_cart_item_image.label',
                                )}`}
                              >
                                <Input
                                  disabled={loadingGetConfig || loading}
                                  id="kit_anchor_cart_item_image"
                                  name="kit_anchor_cart_item_image"
                                  onChange={handleChangeConfig}
                                  value={config?.kit_anchor_cart_item_image}
                                  placeholder={`${'img'}`}
                                />
                              </FormField>
                              <FormField
                                label={`${t(
                                  'ConfigsPage.form.fields.kit_anchor_cart_item_name.label',
                                )}`}
                              >
                                <Input
                                  disabled={loadingGetConfig || loading}
                                  id="kit_anchor_cart_item_name"
                                  name="kit_anchor_cart_item_name"
                                  onChange={handleChangeConfig}
                                  value={config?.kit_anchor_cart_item_name}
                                  placeholder={`${'[data-component="name.short-name"]'}`}
                                />
                              </FormField>
                              <FormField
                                label={`${t(
                                  'ConfigsPage.form.fields.kit_anchor_cart_item_price.label',
                                )}`}
                              >
                                <Input
                                  disabled={loadingGetConfig || loading}
                                  id="kit_anchor_cart_item_price"
                                  name="kit_anchor_cart_item_price"
                                  onChange={handleChangeConfig}
                                  value={config?.kit_anchor_cart_item_price}
                                  placeholder={`${'[data-component="subtotal.value"]'}`}
                                />
                              </FormField>
                              <FormField
                                label={`${t(
                                  'ConfigsPage.form.fields.kit_anchor_cart_item_quantity.label',
                                )}`}
                              >
                                <Input
                                  disabled={loadingGetConfig || loading}
                                  id="kit_anchor_cart_item_quantity"
                                  name="kit_anchor_cart_item_quantity"
                                  onChange={handleChangeConfig}
                                  value={config?.kit_anchor_cart_item_quantity}
                                  placeholder={`${'[data-component="line-item.subtotal"]'}`}
                                />
                              </FormField>
                              <FormField
                                label={`${t(
                                  'ConfigsPage.form.fields.kit_anchor_cart_item_remove.label',
                                )}`}
                              >
                                <Input
                                  disabled={loadingGetConfig || loading}
                                  id="kit_anchor_cart_item_remove"
                                  name="kit_anchor_cart_item_remove"
                                  onChange={handleChangeConfig}
                                  value={config?.kit_anchor_cart_item_remove}
                                  placeholder={`${'[data-component="line-item.remove"]'}`}
                                />
                              </FormField>
                            </Box>
                          </Card.Body>
                        </Card>
                      </Box>
                    </Tabs.Item>
                    {/* TAB COLORS */}
                    <Tabs.Item label={t('ConfigsPage.tabs.colors.title')}>
                      <Box display="grid" gap="6">
                        <Card>
                          <Card.Body>
                            <Box display="grid" gap="6">
                              <Checkbox
                                disabled={loadingGetConfig || loading}
                                name="custom_colors"
                                label={`${t(
                                  'ConfigsPage.form.fields.custom_colors.label',
                                )}`}
                                checked={config?.custom_colors || false}
                                onChange={handleChangeConfig}
                              />
                              <Box
                                display="flex"
                                flexWrap="wrap"
                                gap={{ xs: '4', md: 'none' }}
                              >
                                <Box
                                  width={{
                                    xs: '100%',
                                    md: '50%',
                                  }}
                                  padding={{ xs: 'none', md: '4' }}
                                >
                                  <InputColor
                                    label={`${t(
                                      'ConfigsPage.form.fields.success_color.label',
                                    )}`}
                                    name="success_color"
                                    onChange={handleChangeConfig}
                                    required={true}
                                    disabled={loading || !config}
                                    value={config?.success_color || '#ffffff'}
                                  />
                                </Box>
                                <Box
                                  width={{
                                    xs: '100%',
                                    md: '50%',
                                  }}
                                  padding={{ xs: 'none', md: '4' }}
                                >
                                  <InputColor
                                    label={`${t(
                                      'ConfigsPage.form.fields.primary_color.label',
                                    )}`}
                                    name="primary_color"
                                    onChange={handleChangeConfig}
                                    required={true}
                                    disabled={loading || !config}
                                    value={config?.primary_color || '#ffffff'}
                                  />
                                </Box>
                                <Box
                                  width={{
                                    xs: '100%',
                                    md: '50%',
                                  }}
                                  padding={{ xs: 'none', md: '4' }}
                                >
                                  <InputColor
                                    label={`${t(
                                      'ConfigsPage.form.fields.secondary_color.label',
                                    )}`}
                                    name="secondary_color"
                                    onChange={handleChangeConfig}
                                    required={true}
                                    disabled={loading || !config}
                                    value={config?.secondary_color || '#ffffff'}
                                  />
                                </Box>
                                <Box
                                  width={{
                                    xs: '100%',
                                    md: '50%',
                                  }}
                                  padding={{ xs: 'none', md: '4' }}
                                >
                                  <InputColor
                                    label={`${t(
                                      'ConfigsPage.form.fields.title_color.label',
                                    )}`}
                                    name="title_color"
                                    onChange={handleChangeConfig}
                                    required={true}
                                    disabled={loading || !config}
                                    value={config?.title_color || '#ffffff'}
                                  />
                                </Box>
                                <Box
                                  width={{
                                    xs: '100%',
                                    md: '50%',
                                  }}
                                  padding={{ xs: 'none', md: '4' }}
                                >
                                  <InputColor
                                    label={`${t(
                                      'ConfigsPage.form.fields.text_color.label',
                                    )}`}
                                    name="text_color"
                                    onChange={handleChangeConfig}
                                    required={true}
                                    disabled={loading || !config}
                                    value={config?.text_color || '#ffffff'}
                                  />
                                </Box>
                                <Box
                                  width={{
                                    xs: '100%',
                                    md: '50%',
                                  }}
                                  padding={{ xs: 'none', md: '4' }}
                                >
                                  <InputColor
                                    label={`${t(
                                      'ConfigsPage.form.fields.border_color.label',
                                    )}`}
                                    name="border_color"
                                    onChange={handleChangeConfig}
                                    required={true}
                                    disabled={loading || !config}
                                    value={config?.border_color || '#ffffff'}
                                  />
                                </Box>
                                <Box width="100%">
                                  <Text as="p">{`${t(
                                    'ConfigsPage.form.fields.color_options_title',
                                  )}`}</Text>
                                </Box>
                                <Box
                                  width={{
                                    xs: '100%',
                                    md: '50%',
                                  }}
                                  padding={{ xs: 'none', md: '4' }}
                                >
                                  <InputColor
                                    label={`${t(
                                      'ConfigsPage.form.fields.options_bg_color.label',
                                    )}`}
                                    name="options_bg_color"
                                    onChange={handleChangeConfig}
                                    required={true}
                                    disabled={loading || !config}
                                    value={
                                      config?.options_bg_color || '#ffffff'
                                    }
                                  />
                                </Box>
                                <Box
                                  width={{
                                    xs: '100%',
                                    md: '50%',
                                  }}
                                  padding={{ xs: 'none', md: '4' }}
                                >
                                  <InputColor
                                    label={`${t(
                                      'ConfigsPage.form.fields.options_border_color.label',
                                    )}`}
                                    name="options_border_color"
                                    onChange={handleChangeConfig}
                                    required={true}
                                    disabled={loading || !config}
                                    value={
                                      config?.options_border_color || '#000000'
                                    }
                                  />
                                </Box>
                                <Box
                                  width={{
                                    xs: '100%',
                                    md: '50%',
                                  }}
                                  padding={{ xs: 'none', md: '4' }}
                                >
                                  <InputColor
                                    label={`${t(
                                      'ConfigsPage.form.fields.options_text_color.label',
                                    )}`}
                                    name="options_text_color"
                                    onChange={handleChangeConfig}
                                    required={true}
                                    disabled={loading || !config}
                                    value={
                                      config?.options_text_color || '#000000'
                                    }
                                  />
                                </Box>
                              </Box>
                            </Box>
                          </Card.Body>
                        </Card>
                      </Box>
                    </Tabs.Item>
                  </Tabs>
                  <Box display="flex" marginY="2" justifyContent="flex-end">
                    <Button
                      disabled={loading}
                      appearance="primary"
                      onClick={handleSubmit}
                    >
                      {loading ? (
                        <Spinner color="currentColor" size="small" />
                      ) : (
                        ''
                      )}
                      {t('general.save')}
                    </Button>
                  </Box>
                </Box>
                <Box
                  display={{ xs: 'none', md: 'block' }}
                  width={{ xs: '100%', md: '60%' }}
                  order={{ xs: '1', md: '2' }}
                  boxSizing="border-box"
                >
                  <Box position="sticky" top="0">
                    <Title as="h4">{t('ConfigsPage.preview.title')}</Title>
                    <BuyTogetherModels config={config} />

                    <HelpLink>
                      <Link
                        as="a"
                        href="https://huapps.com.br/ajuda/compre-junto-pro/pt?tab=1"
                        target="_blank"
                        appearance="primary"
                        textDecoration="none"
                      >
                        Mais informações sobre as configurações
                        <Icon
                          source={<ExternalLinkIcon />}
                          color="currentColor"
                        />
                      </Link>
                    </HelpLink>
                  </Box>
                </Box>
              </Box>
            </Layout.Section>
          </Layout>
        </Page.Body>
      </Page>
    </>
  );
};

export default ConfigsPage;
