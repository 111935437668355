import { connect, getStoreInfo, iAmReady } from '@tiendanube/nexo';
import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import nexo from './nexoClient';
import NexoSyncRoute from './NexoSyncRoute';
import {
  BillingPage,
  BlockProductsPage,
  ConfigsPage,
  GroupPage,
  MainPage,
  NewsPage,
  StatisticsPage,
  UnauthorizedPage,
} from './pages';
import { DarkModeProvider, BaseLayout } from './components';

import { ToastProvider } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import ProtectedRoute from './ProtectedRoute';

function App() {
  const [isConnect, setIsConnect] = useState(false);

  const { i18n } = useTranslation();

  // 👇 Clarity aqui
  useEffect(() => {
    // eslint-disable-next-line prefer-rest-params
    (function (c?: any, l?: any, a?: any, r?: any, i?: any, t?: any, y?: any) {
      c[a] =
        c[a] ||
        function (...args: any[]) {
          (c[a].q = c[a].q || []).push(args);
        };
      t = l.createElement(r);
      t.async = 1;
      t.src = 'https://www.clarity.ms/tag/' + i;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    })(window, document, 'clarity', 'script', 'qxhe3dlh1y');
  }, []);

  useEffect(() => {
    connect(nexo).then(async () => {
      setIsConnect(true);
      iAmReady(nexo);

      const store = await getStoreInfo(nexo);
      i18n.changeLanguage(store.language);
    });
  }, []);

  if (!isConnect) return <div>connecting..</div>;

  return (
    <DarkModeProvider>
      <BrowserRouter>
        <ToastProvider>
          <BaseLayout>
            <NexoSyncRoute>
              <Routes>
                <Route
                  path="/"
                  element={<ProtectedRoute element={<MainPage />} />}
                />
                <Route
                  path="/configs"
                  element={<ProtectedRoute element={<ConfigsPage />} />}
                />
                <Route
                  path="/group/:id?"
                  element={<ProtectedRoute element={<GroupPage />} />}
                />
                <Route
                  path="/block-products"
                  element={<ProtectedRoute element={<BlockProductsPage />} />}
                />
                <Route
                  path="/statistics"
                  element={<ProtectedRoute element={<StatisticsPage />} />}
                />
                <Route path="/news" element={<NewsPage />}></Route>
                <Route path="/billing" element={<BillingPage />}></Route>
                <Route path="/unauthorized" element={<UnauthorizedPage />} />
              </Routes>
            </NexoSyncRoute>
          </BaseLayout>
        </ToastProvider>
      </BrowserRouter>
    </DarkModeProvider>
  );
}

export default App;
